export default function pluralize(count) {
  const mod10 = count % 10;
  const mod100 = count % 100;

  if (count === 1) {
    return ['one'];
  } if ([2, 3, 4].includes(mod10) && ![12, 13, 14].includes(mod100)) {
    return ['few'];
  } if ([0, 1, 5, 6, 7, 8, 9].includes(mod10) || [12, 13, 14].includes(mod100)) {
    return ['many'];
  }

  return ['other'];
}
